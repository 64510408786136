import { combineReducers } from "redux";
import loginReducer from "./login/loginReducer";
import adminReducer from "./admin/legacy-admin-reducer";
import superAdminReducer from "./admin/super/super-admin-reducer";
import errorMessage from "./errors/error-reducer";
import { tourialRootReducer } from "../../redux/reducers/tourial-root-reducer";
import { tourialThemeReducer } from "../../redux/reducers/tourial-theme-reducer";
import { micrositeReducer } from "../../redux/reducers/microsites-reducer";
import { liveHelperReducer } from "../../redux/reducers/live-helper-reducer";
import { demoCenterReducer } from "../../redux/reducers/demo-center-reducer";
import { DTReducer } from "../../redux/reducers/dom-tour-reducer";
const rootTourialReducer = combineReducers({
    loginReducer,
    adminReducer,
    superAdminReducer,
    errorMessage,
    tourialRootReducer,
    micrositeReducer,
    tourialThemeReducer,
    liveHelperReducer,
    demoCenterReducer,
    domTourBuilderReducer: DTReducer,
});
export default rootTourialReducer;
