var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
export var TourialApiCalls;
(function (TourialApiCalls) {
    let Accounts;
    (function (Accounts) {
        function getAccountById(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/${accountId}`);
                return data;
            });
        }
        Accounts.getAccountById = getAccountById;
        function getAccountByHubspotPortalId(portalId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/getAccountByHubspotPortalId/${portalId}`);
                return data;
            });
        }
        Accounts.getAccountByHubspotPortalId = getAccountByHubspotPortalId;
        function updateBrandColors(colors) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/accounts/update/brand-colors", { brandColors: colors });
                return null;
            });
        }
        Accounts.updateBrandColors = updateBrandColors;
        function getDomainStatusByAccountId(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/${accountId}/domain-status`);
                return data;
            });
        }
        Accounts.getDomainStatusByAccountId = getDomainStatusByAccountId;
        function updateCustomDomain({ accountId, whiteLabelDomain }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/accounts/update/brand-domain`, {
                    accountId,
                    fullBrandDomain: whiteLabelDomain,
                });
                return null;
            });
        }
        Accounts.updateCustomDomain = updateCustomDomain;
        function deleteCustomDomain() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`/accounts/update/brand-domain`);
                return null;
            });
        }
        Accounts.deleteCustomDomain = deleteCustomDomain;
        function getAllCustomScripts(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/scripts/all/${accountId}`);
                return data;
            });
        }
        Accounts.getAllCustomScripts = getAllCustomScripts;
        function saveCustomScript({ accountId, updatedAllScripts }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/accounts/scripts/add`, {
                    accountId,
                    updatedAllScripts,
                });
                return null;
            });
        }
        Accounts.saveCustomScript = saveCustomScript;
        function deleteCustomScript({ accountId, updatedScripts }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/accounts/scripts/deleteOne`, {
                    accountId,
                    updatedScripts,
                });
                return null;
            });
        }
        Accounts.deleteCustomScript = deleteCustomScript;
    })(Accounts = TourialApiCalls.Accounts || (TourialApiCalls.Accounts = {}));
    let Admin;
    (function (Admin) {
        function createAccount(account) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post("/api/v2/admin/accounts/create", account);
                return data;
            });
        }
        Admin.createAccount = createAccount;
        function getAllAccounts() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/admin/accounts/all");
                return data;
            });
        }
        Admin.getAllAccounts = getAllAccounts;
        function getInternalSalesforceAccounts() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/admin/salesforce/records/account");
                return data;
            });
        }
        Admin.getInternalSalesforceAccounts = getInternalSalesforceAccounts;
    })(Admin = TourialApiCalls.Admin || (TourialApiCalls.Admin = {}));
    let Auth;
    (function (Auth) {
        function getFirebaseToken() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/auth/firebasetoken");
                return data.token;
            });
        }
        Auth.getFirebaseToken = getFirebaseToken;
    })(Auth = TourialApiCalls.Auth || (TourialApiCalls.Auth = {}));
    let LegacyAnalytics;
    (function (LegacyAnalytics) {
        function getTourialOutboundSessions({ searchAfter }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/insights/tourialOutboundSessions", { params: { searchAfter } });
                return data;
            });
        }
        LegacyAnalytics.getTourialOutboundSessions = getTourialOutboundSessions;
        function getTourialReferringUrls({ tourialId, from, to, activeVariantName, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/insights/${tourialId}/getTourialReferringUrls`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                    },
                });
                return data;
            });
        }
        LegacyAnalytics.getTourialReferringUrls = getTourialReferringUrls;
        function getUniqueVisitorsByPageOrView({ tourialId, from, to, activeVariantName, viewlevel, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/insights/${tourialId}/uniqueVisitorsByPageOrView`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        viewlevel,
                        shareLinkId,
                    },
                });
                return data;
            });
        }
        LegacyAnalytics.getUniqueVisitorsByPageOrView = getUniqueVisitorsByPageOrView;
        function getInsightsHeaderData({ tourialId, from, to, activeVariantName, baselineUrl, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield axios.get(`/api/v2/insights/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        baselineUrl,
                        shareLinkId,
                    },
                });
            });
        }
        LegacyAnalytics.getInsightsHeaderData = getInsightsHeaderData;
        function getExternalLinkInteractions({ tourialId, from, to, activeVariantName, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/insights/${tourialId}/externalLinkInteractions`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                    },
                });
                return data;
            });
        }
        LegacyAnalytics.getExternalLinkInteractions = getExternalLinkInteractions;
        function getSessionsByDevice({ tourialId, from, to, activeVariantName, interval, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/insights/${tourialId}/sessionsByDevice`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        interval,
                        shareLinkId,
                    },
                });
                return data;
            });
        }
        LegacyAnalytics.getSessionsByDevice = getSessionsByDevice;
        function getAverageTimeByPageOrView({ tourialId, from, to, activeVariantName, viewlevel, shareLinkId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/insights/${tourialId}/averageTimeByPageOrView`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        viewlevel,
                        shareLinkId,
                    },
                });
                return data;
            });
        }
        LegacyAnalytics.getAverageTimeByPageOrView = getAverageTimeByPageOrView;
    })(LegacyAnalytics = TourialApiCalls.LegacyAnalytics || (TourialApiCalls.LegacyAnalytics = {}));
    let Analytics;
    (function (Analytics) {
        function getTimeSeriesData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/timeSeriesData/${params.tourialId || ""}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getTimeSeriesData = getTimeSeriesData;
        /** use this for the comparison to baseline data (aka the percentage change) */
        function getTopRowMetrics(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/topRowMetrics/${params.tourialId || ""}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getTopRowMetrics = getTopRowMetrics;
        function getThirdRowMetrics({ tourialId, from, to, activeVariantName, shareLinkId, isTourPage, micrositeId, parentUrl, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/thirdRowMetrics/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                        isTourPage,
                        micrositeId,
                        parentUrl,
                    },
                });
                return data;
            });
        }
        Analytics.getThirdRowMetrics = getThirdRowMetrics;
        function getFormsGraphDataByTour({ tourialId, from, to, activeVariantName, shareLinkId, interval, isTourPage, micrositeId, parentUrl, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/formsGraphData/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                        interval,
                        isTourPage,
                        micrositeId,
                        parentUrl,
                    },
                });
                return data;
            });
        }
        Analytics.getFormsGraphDataByTour = getFormsGraphDataByTour;
        function getFormsGraphDataByDemoCenter(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/formsGraphDataByDemoCenter`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getFormsGraphDataByDemoCenter = getFormsGraphDataByDemoCenter;
        function getCtaPieChartData({ tourialId, from, to, activeVariantName, shareLinkId, isTourPage, micrositeId, parentUrl, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/ctaPieChartData/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                        isTourPage,
                        micrositeId,
                        parentUrl,
                    },
                });
                return data;
            });
        }
        Analytics.getCtaPieChartData = getCtaPieChartData;
        function getCtaPieChartDataByDemoCenter(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/ctaPieChartDataByDemoCenter`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getCtaPieChartDataByDemoCenter = getCtaPieChartDataByDemoCenter;
        function getDeviceTypeData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/deviceTypeData/${params.tourialId || ""}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDeviceTypeData = getDeviceTypeData;
        function getSourceTrafficData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/sourceTrafficData/${params.tourialId || ""}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getSourceTrafficData = getSourceTrafficData;
        function getReferringUrls(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/referringUrls/${params.tourialId || ""}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getReferringUrls = getReferringUrls;
        function getDemoCenterReferringUrls(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/referringUrlsByDemoCenter`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterReferringUrls = getDemoCenterReferringUrls;
        function getLinkPerformanceData({ tourialId, from, to, activeVariantName, isTourPage, micrositeId, parentUrl, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/linkPerformanceData/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        isTourPage,
                        micrositeId,
                        parentUrl,
                    },
                });
                return data;
            });
        }
        Analytics.getLinkPerformanceData = getLinkPerformanceData;
        function getDCLinkPerformanceData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/linkDCPerformanceData`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDCLinkPerformanceData = getDCLinkPerformanceData;
        function getStepPerformanceData({ tourialId, from, to, activeVariantName, shareLinkId, isTourPage, micrositeId, parentUrl, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/stepPerformanceData/${tourialId}`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        shareLinkId,
                        isTourPage,
                        micrositeId,
                        parentUrl,
                    },
                });
                return data;
            });
        }
        Analytics.getStepPerformanceData = getStepPerformanceData;
        function getOverviewTourialListData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/overviewTourialListData`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getOverviewTourialListData = getOverviewTourialListData;
        function getTourialListDataByMicrosite({ from, to, isMicrositesOverview, micrositeId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/tourial-list-data-by-microsite`, {
                    params: {
                        from,
                        to,
                        isMicrositesOverview,
                        micrositeId,
                    },
                });
                return data;
            });
        }
        Analytics.getTourialListDataByMicrosite = getTourialListDataByMicrosite;
        function getTourialListDataByDemoCenter({ from, to, isDemocentersOverview, democenterId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/tourial-list-data-by-democenter`, {
                    params: {
                        from,
                        to,
                        isDemocentersOverview,
                        democenterId,
                    },
                });
                return data;
            });
        }
        Analytics.getTourialListDataByDemoCenter = getTourialListDataByDemoCenter;
        function getOverviewMicrositeListData({ from, to, activeVariantName, isMicrositesOverview, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/overview-microsite-list-data`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getOverviewMicrositeListData = getOverviewMicrositeListData;
        function getOverviewDemoCenterListData({ from, to, activeVariantName, isDemocentersOverview, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/overview-democenter-list-data`, {
                    params: {
                        from,
                        to,
                        activeVariantName,
                        isDemocentersOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getOverviewDemoCenterListData = getOverviewDemoCenterListData;
        function getMicrositeTopRowMetrics({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-top-row-metrics`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeTopRowMetrics = getMicrositeTopRowMetrics;
        function getDemoCenterTopRowMetrics(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-top-row-metrics`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterTopRowMetrics = getDemoCenterTopRowMetrics;
        function getMicrositeTotalCtaClicks({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-total-cta-clicks`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeTotalCtaClicks = getMicrositeTotalCtaClicks;
        function getDemoCenterTotalCtaClicks(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-total-cta-clicks`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterTotalCtaClicks = getDemoCenterTotalCtaClicks;
        function getDemoCenterAverageModulesViewed(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-avg-modules-viewed`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterAverageModulesViewed = getDemoCenterAverageModulesViewed;
        function getMicrositeAverageSessionTime({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-average-session-time`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeAverageSessionTime = getMicrositeAverageSessionTime;
        function getDemoCenterAverageSessionTime(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-average-session-time`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterAverageSessionTime = getDemoCenterAverageSessionTime;
        function getDemoCenterEngagementOver30Seconds(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-engagement-over-30-seconds`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterEngagementOver30Seconds = getDemoCenterEngagementOver30Seconds;
        function getDemoCenterModuleStats(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/dc-module-stats`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterModuleStats = getDemoCenterModuleStats;
        function getDemoCenterContentStats(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/dc-content-stats`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterContentStats = getDemoCenterContentStats;
        function getMicrositeTimeSeriesData({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, interval, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-time-series-data`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                        interval,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeTimeSeriesData = getMicrositeTimeSeriesData;
        function getDemoCenterTimeSeriesData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-time-series-data`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getDemoCenterTimeSeriesData = getDemoCenterTimeSeriesData;
        function getMicrositeDeviceTypeData({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, interval, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-device-type-data`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                        interval,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeDeviceTypeData = getMicrositeDeviceTypeData;
        function getDemoCenterDeviceTypeData({ democenterId = "", from, to, activeVariantName, isDemocentersOverview = false, interval, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-device-type-data`, {
                    params: {
                        democenterId,
                        from,
                        to,
                        activeVariantName,
                        isDemocentersOverview,
                        interval,
                    },
                });
                return data;
            });
        }
        Analytics.getDemoCenterDeviceTypeData = getDemoCenterDeviceTypeData;
        function getMicrositeSourceTrafficData({ micrositeId = "", from, to, activeVariantName, isMicrositesOverview = false, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/microsite-source-traffic-data`, {
                    params: {
                        micrositeId,
                        from,
                        to,
                        activeVariantName,
                        isMicrositesOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getMicrositeSourceTrafficData = getMicrositeSourceTrafficData;
        function getDemoCenterSourceTrafficData({ democenterId = "", from, to, activeVariantName, isDemocentersOverview = false, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/democenter-source-traffic-data`, {
                    params: {
                        democenterId,
                        from,
                        to,
                        activeVariantName,
                        isDemocentersOverview,
                    },
                });
                return data;
            });
        }
        Analytics.getDemoCenterSourceTrafficData = getDemoCenterSourceTrafficData;
        function getTourialParentUrls(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/parent-urls/${tourialId}`);
                return data;
            });
        }
        Analytics.getTourialParentUrls = getTourialParentUrls;
        function getIndividualSessionData(params, type) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/individual-sessions/${type}`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getIndividualSessionData = getIndividualSessionData;
        function getLinkActivity({ from, to, createdBy, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/linkActivity`, {
                    params: {
                        from,
                        to,
                        isSessionsOverview: true,
                        createdBy,
                    },
                });
                return data;
            });
        }
        Analytics.getLinkActivity = getLinkActivity;
        function getOppData(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/analytics/oppData`, {
                    params,
                });
                return data;
            });
        }
        Analytics.getOppData = getOppData;
    })(Analytics = TourialApiCalls.Analytics || (TourialApiCalls.Analytics = {}));
    let AppEvents;
    (function (AppEvents) {
        function postAppEvent(event) {
            axios.post("/api/v2/appEvents", event).catch((e) => console.error("failed to post app event", e));
        }
        AppEvents.postAppEvent = postAppEvent;
    })(AppEvents = TourialApiCalls.AppEvents || (TourialApiCalls.AppEvents = {}));
    let Builder;
    (function (Builder) {
        function getMediaSearchResults(searchTerm, pageNumber) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/media-search`, { params: { searchTerm, pageNumber } });
                return data;
            });
        }
        Builder.getMediaSearchResults = getMediaSearchResults;
        function postMediaSearchDownload(downloadLocation) {
            void axios.post(`/api/v2/media-search/download`, { downloadLocation });
        }
        Builder.postMediaSearchDownload = postMediaSearchDownload;
    })(Builder = TourialApiCalls.Builder || (TourialApiCalls.Builder = {}));
    let Events;
    (function (Events) {
        function postEvent(event) {
            return __awaiter(this, void 0, void 0, function* () {
                try {
                    return yield axios.post("/api/v2/events", event);
                }
                catch (e) {
                    console.error("failed to post event", e);
                }
            });
        }
        Events.postEvent = postEvent;
        function getSummary({ id, from, to, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`api/v2/events/summary/${id}?from=${from}${to ? `&to=${to}` : ""}`);
                return data;
            });
        }
        Events.getSummary = getSummary;
    })(Events = TourialApiCalls.Events || (TourialApiCalls.Events = {}));
    let Microsites;
    (function (Microsites) {
        function createMicrosite(microsite) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post("/api/v2/microsites", microsite);
                return data;
            });
        }
        Microsites.createMicrosite = createMicrosite;
        function deleteMicrosite(micrositeId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/microsites/${micrositeId}`);
                return data;
            });
        }
        Microsites.deleteMicrosite = deleteMicrosite;
        function getMicrosites() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/microsites");
                return data;
            });
        }
        Microsites.getMicrosites = getMicrosites;
        function getMicrositeById(micrositeId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/microsites/${micrositeId}`);
                return data;
            });
        }
        Microsites.getMicrositeById = getMicrositeById;
        function getMicrositeIdBySlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/microsites/idBySlug/${slug}`);
                return data;
            });
        }
        Microsites.getMicrositeIdBySlug = getMicrositeIdBySlug;
        function getMicrositeBySlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/microsites/slug/${slug}`);
                return data;
            });
        }
        Microsites.getMicrositeBySlug = getMicrositeBySlug;
        function updateMicrosite(microsite) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`/api/v2/microsites/${microsite._id}`, microsite);
                return data;
            });
        }
        Microsites.updateMicrosite = updateMicrosite;
        function copyMicrosite(micrositeId, targetAccountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/microsites/${micrositeId}/copy${targetAccountId ? `/${targetAccountId}` : ""}`);
                return data;
            });
        }
        Microsites.copyMicrosite = copyMicrosite;
        function toggleMicrositePublishFlag(micrositeId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/microsites/${micrositeId}/togglePublishFlag`);
                return data;
            });
        }
        Microsites.toggleMicrositePublishFlag = toggleMicrositePublishFlag;
        function getMicrositeOverviewList() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/microsites/overview-list`);
                return data;
            });
        }
        Microsites.getMicrositeOverviewList = getMicrositeOverviewList;
        function getMicrositeIdsByTourial(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/microsites/tourial/${tourialId}`);
                return data;
            });
        }
        Microsites.getMicrositeIdsByTourial = getMicrositeIdsByTourial;
    })(Microsites = TourialApiCalls.Microsites || (TourialApiCalls.Microsites = {}));
    let DemoCenters;
    (function (DemoCenters) {
        function createDemoCenter(demoCenter, name, workspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post("/api/v2/democenters", { demoCenter, name, workspaceId });
                return data;
            });
        }
        DemoCenters.createDemoCenter = createDemoCenter;
        function deleteDemoCenter(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/democenters/${id}`);
                return data;
            });
        }
        DemoCenters.deleteDemoCenter = deleteDemoCenter;
        function getDemoCenters() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/democenters");
                return data;
            });
        }
        DemoCenters.getDemoCenters = getDemoCenters;
        function getDemoCenterById(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/${id}`);
                return data;
            });
        }
        DemoCenters.getDemoCenterById = getDemoCenterById;
        function getDemoCenterIdBySlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/idBySlug/${slug}`);
                return data;
            });
        }
        DemoCenters.getDemoCenterIdBySlug = getDemoCenterIdBySlug;
        function getPublicDemoCenterBySlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/slug/${slug}`);
                return data;
            });
        }
        DemoCenters.getPublicDemoCenterBySlug = getPublicDemoCenterBySlug;
        function getPublicDemoCenterByShareSlug(shareSlug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/shareslug/${shareSlug}`);
                return data;
            });
        }
        DemoCenters.getPublicDemoCenterByShareSlug = getPublicDemoCenterByShareSlug;
        function getDemoCenterBySlugWithAuthorization(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/slug-with-auth/${slug}`);
                return data;
            });
        }
        DemoCenters.getDemoCenterBySlugWithAuthorization = getDemoCenterBySlugWithAuthorization;
        function updateDemoCenter(demoCenter) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`/api/v2/democenters/${demoCenter._id}`, demoCenter);
                return data;
            });
        }
        DemoCenters.updateDemoCenter = updateDemoCenter;
        function copyDemoCenter(demoCenterId, targetAccountId, workspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/democenters/${demoCenterId}/copy${targetAccountId ? `/${targetAccountId}` : ""}`, { workspaceId });
                return data;
            });
        }
        DemoCenters.copyDemoCenter = copyDemoCenter;
        function getVideoDetails(inputUrl, inputType) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/video-details/${inputType.toLowerCase()}?inputUrl=${inputUrl}`);
                return data;
            });
        }
        DemoCenters.getVideoDetails = getVideoDetails;
        function getIsEmbeddable(inputUrl) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/democenters/isEmbeddable?inputUrl=${inputUrl.toLowerCase()}`);
                return data;
            });
        }
        DemoCenters.getIsEmbeddable = getIsEmbeddable;
    })(DemoCenters = TourialApiCalls.DemoCenters || (TourialApiCalls.DemoCenters = {}));
    let Forms;
    (function (Forms) {
        function createForm(form) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/forms", form);
                return null;
            });
        }
        Forms.createForm = createForm;
        function deleteForm(formId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/forms/${formId}`);
                return data;
            });
        }
        Forms.deleteForm = deleteForm;
        function getForms() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/forms");
                return data;
            });
        }
        Forms.getForms = getForms;
        function getFormById(formId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/forms/${formId}`);
                return data;
            });
        }
        Forms.getFormById = getFormById;
        function getIntegrationForms({ target, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/forms/integrations/${target}`);
                return data;
            });
        }
        Forms.getIntegrationForms = getIntegrationForms;
        function getIntegrationFormFields({ target, formId, }) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/forms/integrations/${target}/${formId}`);
                return data;
            });
        }
        Forms.getIntegrationFormFields = getIntegrationFormFields;
        function getIntegrationsAvailable() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/forms/integrations");
                return data;
            });
        }
        Forms.getIntegrationsAvailable = getIntegrationsAvailable;
        function submitForm(submission) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/forms/submit", submission);
                return null;
            });
        }
        Forms.submitForm = submitForm;
        function updateForm(form) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`/api/v2/forms/${form._id}`, form);
                return data;
            });
        }
        Forms.updateForm = updateForm;
        function getTourNamesUsingForm(formId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/forms/getTourNamesUsingForm/${formId}`);
                return data;
            });
        }
        Forms.getTourNamesUsingForm = getTourNamesUsingForm;
    })(Forms = TourialApiCalls.Forms || (TourialApiCalls.Forms = {}));
    let Themes;
    (function (Themes) {
        function updateTheme(theme) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/themes", theme);
                return null;
            });
        }
        Themes.updateTheme = updateTheme;
        function getDefaultTheme() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/themes/default");
                return data;
            });
        }
        Themes.getDefaultTheme = getDefaultTheme;
        function getThemePrimaryColor(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/themes/primaryColor/${accountId}`);
                return data;
            });
        }
        Themes.getThemePrimaryColor = getThemePrimaryColor;
        function applyThemeToTarget(brandingType, targetType, targetId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/themes/apply", { brandingType, targetType, targetId });
                return null;
            });
        }
        Themes.applyThemeToTarget = applyThemeToTarget;
    })(Themes = TourialApiCalls.Themes || (TourialApiCalls.Themes = {}));
    let Integrations;
    (function (Integrations) {
        function getIntegrationsPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/integrationPageData`);
                return data;
            });
        }
        Integrations.getIntegrationsPageData = getIntegrationsPageData;
        function upsertIntegration(integration) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/integrations/upsertIntegration`, { integration });
                return null;
            });
        }
        Integrations.upsertIntegration = upsertIntegration;
        function getHubspotPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/hubspot/pagedata`);
                return data;
            });
        }
        Integrations.getHubspotPageData = getHubspotPageData;
        function deleteHubspotIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/hubspot");
                return null;
            });
        }
        Integrations.deleteHubspotIntegration = deleteHubspotIntegration;
        function getHubspotMeetings() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/hubspot/meetings`);
                return data;
            });
        }
        Integrations.getHubspotMeetings = getHubspotMeetings;
        function updateHubspotMeetings(meetings) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/api/v2/integrations/hubspot/meetings`, { meetings });
                return null;
            });
        }
        Integrations.updateHubspotMeetings = updateHubspotMeetings;
        function getMarketoPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/marketo/pagedata`);
                return data;
            });
        }
        Integrations.getMarketoPageData = getMarketoPageData;
        function postMarketoSetup(setup) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/integrations/marketo/setup`, setup);
                return data;
            });
        }
        Integrations.postMarketoSetup = postMarketoSetup;
        function getMarketoForms() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/marketo/forms`);
                return data;
            });
        }
        Integrations.getMarketoForms = getMarketoForms;
        function getMarketoFormFields(formId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/marketo/forms/${formId}`);
                return data;
            });
        }
        Integrations.getMarketoFormFields = getMarketoFormFields;
        function deleteMarketoIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/marketo");
                return null;
            });
        }
        Integrations.deleteMarketoIntegration = deleteMarketoIntegration;
        function marketoTracking(enabled) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/integrations/marketotracking", { enabled });
                return null;
            });
        }
        Integrations.marketoTracking = marketoTracking;
        function marketoMicrositeTracking(enabled) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/integrations/marketomicrositetracking", { enabled });
                return null;
            });
        }
        Integrations.marketoMicrositeTracking = marketoMicrositeTracking;
        function deleteSalesforceIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/salesforce");
                return null;
            });
        }
        Integrations.deleteSalesforceIntegration = deleteSalesforceIntegration;
        function salesforceEngagement(enabled) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/integrations/salesforce/engagement`, { enabled });
                return data.success;
            });
        }
        Integrations.salesforceEngagement = salesforceEngagement;
        function getPardotPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/pardot/pagedata`);
                return data;
            });
        }
        Integrations.getPardotPageData = getPardotPageData;
        function deletePardotIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/pardot");
                return null;
            });
        }
        Integrations.deletePardotIntegration = deletePardotIntegration;
        function getPardotForms() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/pardot/forms`);
                return data;
            });
        }
        Integrations.getPardotForms = getPardotForms;
        function getPardotFormFields(formId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/pardot/forms/${formId}`);
                return data;
            });
        }
        Integrations.getPardotFormFields = getPardotFormFields;
        function pardotTracking(enabled) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post("/api/v2/integrations/pardottracking", { enabled });
                return null;
            });
        }
        Integrations.pardotTracking = pardotTracking;
        function getCalendlyPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/calendly/pagedata`);
                return data;
            });
        }
        Integrations.getCalendlyPageData = getCalendlyPageData;
        function deleteCalendlyIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/calendly");
                return null;
            });
        }
        Integrations.deleteCalendlyIntegration = deleteCalendlyIntegration;
        function getCalendlyLinks() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/calendly/links`);
                return data;
            });
        }
        Integrations.getCalendlyLinks = getCalendlyLinks;
        function getChiliPiperPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/chilipiper/pagedata`);
                return data;
            });
        }
        Integrations.getChiliPiperPageData = getChiliPiperPageData;
        function getChiliPiperCredentials() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/chilipiper/credentials`);
                return data;
            });
        }
        Integrations.getChiliPiperCredentials = getChiliPiperCredentials;
        function updateChiliPiperCredentials({ cpTenantDomain, cpRouters }) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/api/v2/integrations/chilipiper/credentials`, {
                    cpTenantDomain,
                    cpRouters,
                });
                return null;
            });
        }
        Integrations.updateChiliPiperCredentials = updateChiliPiperCredentials;
        function deleteChiliPiperIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/chilipiper");
                return null;
            });
        }
        Integrations.deleteChiliPiperIntegration = deleteChiliPiperIntegration;
        function getZapierPageData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/integrations/zapier/pagedata`);
                return data;
            });
        }
        Integrations.getZapierPageData = getZapierPageData;
        function deleteZapierIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete("/api/v2/integrations/zapier");
                return null;
            });
        }
        Integrations.deleteZapierIntegration = deleteZapierIntegration;
        function updateSlackTourialContentChannel(channelId) {
            return __awaiter(this, void 0, void 0, function* () {
                return axios.post("/api/v2/integrations/slack/settourialcontentchannel", { channelId });
            });
        }
        Integrations.updateSlackTourialContentChannel = updateSlackTourialContentChannel;
        function deleteSlackIntegration() {
            return __awaiter(this, void 0, void 0, function* () {
                return axios.delete("/api/v2/integrations/slack");
            });
        }
        Integrations.deleteSlackIntegration = deleteSlackIntegration;
        function getSalesforceAccounts() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/integrations/salesforce/records/account");
                return data;
            });
        }
        Integrations.getSalesforceAccounts = getSalesforceAccounts;
        function getSalesforceAccountOpps(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                return axios.get(`/api/v2/integrations/salesforce/records/opportunity?accountId=${accountId}`);
            });
        }
        Integrations.getSalesforceAccountOpps = getSalesforceAccountOpps;
    })(Integrations = TourialApiCalls.Integrations || (TourialApiCalls.Integrations = {}));
    let Tourials;
    (function (Tourials) {
        function getTourial(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/v2/tourial/${tourialId}`);
                return data;
            });
        }
        Tourials.getTourial = getTourial;
        function getTourialWithMetadata(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/v2/tourialWithMetadata/${tourialId}`);
                return data;
            });
        }
        Tourials.getTourialWithMetadata = getTourialWithMetadata;
        // Homepage - tour list with screen data
        function getTourialList() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/tourials/list`);
                return data;
            });
        }
        Tourials.getTourialList = getTourialList;
        // Import Screen - v3 tour list by account for importing screen groups
        function getTourialListV3() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/tourials/listV3`);
                return data;
            });
        }
        Tourials.getTourialListV3 = getTourialListV3;
        function getTourialListForMicrosite(micrositeId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/tourials/list-for-microsite/${micrositeId}`);
                return data;
            });
        }
        Tourials.getTourialListForMicrosite = getTourialListForMicrosite;
        function getTourialListForDemoCenter(democenterId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/tourials/list-for-democenter/${democenterId}`);
                return data;
            });
        }
        Tourials.getTourialListForDemoCenter = getTourialListForDemoCenter;
        function postTourialClone(tourId, accountId, workspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/v2/${tourId}/clone${accountId ? `/${accountId}` : ""}`, { workspaceId });
                return data;
            });
        }
        Tourials.postTourialClone = postTourialClone;
        function postTourialCloneV2toV3(tourId, accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/v2/${tourId}/clone-v2-to-v3${accountId ? `/${accountId}` : ""}`);
                return null;
            });
        }
        Tourials.postTourialCloneV2toV3 = postTourialCloneV2toV3;
        function deleteTourial(tourId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.delete(`/v2/${tourId}`);
                return null;
            });
        }
        Tourials.deleteTourial = deleteTourial;
        function postTourialPublish(tourId, publish) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/v2/${tourId}/publish/${publish}`);
                return null;
            });
        }
        Tourials.postTourialPublish = postTourialPublish;
        function postTourialName(tourId, name) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/api/v2/tourials/${tourId}/rename/${name}`);
                return null;
            });
        }
        Tourials.postTourialName = postTourialName;
        function postTourialArchive(tourId) {
            return __awaiter(this, void 0, void 0, function* () {
                yield axios.post(`/api/v2/tourials/${tourId}/archive`);
                return null;
            });
        }
        Tourials.postTourialArchive = postTourialArchive;
        function postNewTourial(name, workspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/v2/create`, { name, version: 3, workspaceId });
                return data;
            });
        }
        Tourials.postNewTourial = postNewTourial;
        function postTrimVideo(src, start, duration) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/tourials/trim-video`, {
                    src,
                    start,
                    duration,
                });
                return data;
            });
        }
        Tourials.postTrimVideo = postTrimVideo;
    })(Tourials = TourialApiCalls.Tourials || (TourialApiCalls.Tourials = {}));
    let Users;
    (function (Users) {
        function getTourialAuthData() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/users/tourialAuthData`);
                return data;
            });
        }
        Users.getTourialAuthData = getTourialAuthData;
        function changePassword(args) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield axios.post(`/users/update/password`, args);
            });
        }
        Users.changePassword = changePassword;
        function setShowChromeExtensionModal(args) {
            return __awaiter(this, void 0, void 0, function* () {
                return yield axios.post(`/users/update/showChromeExtensionModal`, args);
            });
        }
        Users.setShowChromeExtensionModal = setShowChromeExtensionModal;
        function getAllAccountUsers() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/users/getAllAccountUsers`);
                return data;
            });
        }
        Users.getAllAccountUsers = getAllAccountUsers;
        function getUserEmailById(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/users/getUserEmailById/${id}`);
                return data;
            });
        }
        Users.getUserEmailById = getUserEmailById;
    })(Users = TourialApiCalls.Users || (TourialApiCalls.Users = {}));
    let AdminUserManagement;
    (function (AdminUserManagement) {
        function getUsers() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/admin-user-management/get-users`);
                return data;
            });
        }
        AdminUserManagement.getUsers = getUsers;
        function setIsActive(userId, isActive) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.patch(`/api/v2/admin-user-management/set-is-active`, { userId, isActive });
                return data;
            });
        }
        AdminUserManagement.setIsActive = setIsActive;
        function setUserAccess(userId, accessLevel) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.patch(`/api/v2/admin-user-management/set-user-access`, {
                    userId,
                    accessLevel,
                });
                return data;
            });
        }
        AdminUserManagement.setUserAccess = setUserAccess;
        function setEmail(userId, email) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.patch(`/api/v2/admin-user-management/set-email`, { userId, email });
                return data;
            });
        }
        AdminUserManagement.setEmail = setEmail;
        function setUsername(userId, username) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.patch(`/api/v2/admin-user-management/set-username`, { userId, username });
                return data;
            });
        }
        AdminUserManagement.setUsername = setUsername;
        function createUser(email, username, userAccess) {
            return __awaiter(this, void 0, void 0, function* () {
                const payload = { email, username, userAccess };
                const { data } = yield axios.post(`/api/v2/admin-user-management/create-user`, payload);
                return data;
            });
        }
        AdminUserManagement.createUser = createUser;
    })(AdminUserManagement = TourialApiCalls.AdminUserManagement || (TourialApiCalls.AdminUserManagement = {}));
    let ShareLinks;
    (function (ShareLinks) {
        function getShareLink(params) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/share-links`, {
                    params: params,
                });
                return data;
            });
        }
        ShareLinks.getShareLink = getShareLink;
        function fetchShareLinksByTourial(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/share-links/by-tour/${tourialId}`);
                return data;
            });
        }
        ShareLinks.fetchShareLinksByTourial = fetchShareLinksByTourial;
        function fetchShareLinksByDemoCenter(demoCenterId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/share-links/by-dc/${demoCenterId}`);
                return data;
            });
        }
        ShareLinks.fetchShareLinksByDemoCenter = fetchShareLinksByDemoCenter;
        function patchShareLink(id, patch) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.patch(`/api/v2/share-links/${id}`, Object.assign({}, patch));
                return data;
            });
        }
        ShareLinks.patchShareLink = patchShareLink;
        function createShareLink(shareLink) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/share-links`, shareLink);
                return data;
            });
        }
        ShareLinks.createShareLink = createShareLink;
        function deleteShareLink(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/share-links/${id}`);
                return data;
            });
        }
        ShareLinks.deleteShareLink = deleteShareLink;
    })(ShareLinks = TourialApiCalls.ShareLinks || (TourialApiCalls.ShareLinks = {}));
    let DomCapture;
    (function (DomCapture) {
        function getDomCapture(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/dom-capture/${id}`);
                return data;
            });
        }
        DomCapture.getDomCapture = getDomCapture;
        function getDomCapturePreviewsByUserAccount() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get("/api/v2/dom-capture/previews-by-user-account");
                return data;
            });
        }
        DomCapture.getDomCapturePreviewsByUserAccount = getDomCapturePreviewsByUserAccount;
        function getDomCapturePreviewsByTourId(tourId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-capture/previews-by-tour-id/${tourId}`);
                return data;
            });
        }
        DomCapture.getDomCapturePreviewsByTourId = getDomCapturePreviewsByTourId;
        function getDomCaptureThumbnail(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-capture/thumbnail/${id}`);
                return data;
            });
        }
        DomCapture.getDomCaptureThumbnail = getDomCaptureThumbnail;
        function getDomCaptureThumbnails(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-capture/thumbnails/${id}`);
                return data;
            });
        }
        DomCapture.getDomCaptureThumbnails = getDomCaptureThumbnails;
        function postEditedDomCapture(id, html) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/dom-capture/edited-dom-capture`, { html, id });
                return data;
            });
        }
        DomCapture.postEditedDomCapture = postEditedDomCapture;
        // name is currently the only editable field. Expand as needed
        function updateDomCapture(id, updateFields) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`/api/v2/dom-capture/${id}`, updateFields);
                return data;
            });
        }
        DomCapture.updateDomCapture = updateDomCapture;
        function importDomCaptures(fromHT, toHT, captureIds) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/dom-capture/import-captures`, { fromHT, toHT, captureIds });
                return data;
            });
        }
        DomCapture.importDomCaptures = importDomCaptures;
    })(DomCapture = TourialApiCalls.DomCapture || (TourialApiCalls.DomCapture = {}));
    let DemoChatbot;
    (function (DemoChatbot) {
        function getDemoChatbot() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/get/demoChatbot`);
                function calculateAspectRatio(imageUrl) {
                    return new Promise((resolve, reject) => {
                        const img = new Image();
                        img.src = imageUrl;
                        img.onload = () => {
                            const aspectRatio = img.width / img.height;
                            resolve(aspectRatio);
                        };
                        img.onerror = reject;
                    });
                }
                if (data.backgroundImage) {
                    const aspectRatio = yield calculateAspectRatio(data.backgroundImage);
                    return Object.assign(Object.assign({}, data), { aspectRatio });
                }
                return data;
            });
        }
        DemoChatbot.getDemoChatbot = getDemoChatbot;
        function getDemoChatbotByAccount(accountId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/accounts/get/demoChatbotByAccount/${accountId}`);
                return data;
            });
        }
        DemoChatbot.getDemoChatbotByAccount = getDemoChatbotByAccount;
    })(DemoChatbot = TourialApiCalls.DemoChatbot || (TourialApiCalls.DemoChatbot = {}));
    let DomTours;
    (function (DomTours) {
        function updatePublishedDomTour(domTour) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.put(`/api/v2/dom-tour/${domTour._id}`, domTour);
                return data;
            });
        }
        DomTours.updatePublishedDomTour = updatePublishedDomTour;
        function getDomTourFromSlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/slug/${slug}`);
                return data;
            });
        }
        DomTours.getDomTourFromSlug = getDomTourFromSlug;
        function getDomTourById(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/${id}`);
                return data;
            });
        }
        DomTours.getDomTourById = getDomTourById;
        function getPublishedDomTourById(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/published/id/${id}`);
                return data;
            });
        }
        DomTours.getPublishedDomTourById = getPublishedDomTourById;
        function getDomTourFromId(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/${id}`);
                return data;
            });
        }
        DomTours.getDomTourFromId = getDomTourFromId;
        function getDomTourList() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/list`);
                return data;
            });
        }
        DomTours.getDomTourList = getDomTourList;
        function deleteDomTour(id) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/dom-tour/${id}`);
                return data;
            });
        }
        DomTours.deleteDomTour = deleteDomTour;
        function getDomTourIdBySlug(slug) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/dom-tour/idBySlug/${slug}`);
                return data;
            });
        }
        DomTours.getDomTourIdBySlug = getDomTourIdBySlug;
        function postHTClone(tourialId, accountId, workspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/dom-tour/${tourialId}/clone${accountId ? `/${accountId}` : ""}`, {
                    workspaceId,
                });
                return data;
            });
        }
        DomTours.postHTClone = postHTClone;
    })(DomTours = TourialApiCalls.DomTours || (TourialApiCalls.DomTours = {}));
    let Versions;
    (function (Versions) {
        function getVersions(tourialId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/versions/${tourialId}`);
                return data;
            });
        }
        Versions.getVersions = getVersions;
    })(Versions = TourialApiCalls.Versions || (TourialApiCalls.Versions = {}));
    let Workspaces;
    (function (Workspaces) {
        function listWorkspaces() {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.get(`/api/v2/workspaces`);
                return data;
            });
        }
        Workspaces.listWorkspaces = listWorkspaces;
        function createWorkspace(workspaceName, userEmails, isPublic) {
            return __awaiter(this, void 0, void 0, function* () {
                const payload = { name: workspaceName, userEmails, isPublic };
                const { data } = yield axios.post(`/api/v2/workspaces`, payload);
                return data;
            });
        }
        Workspaces.createWorkspace = createWorkspace;
        function updateWorkspace(workspaceId, newName, userEmails, isPublic) {
            return __awaiter(this, void 0, void 0, function* () {
                const payload = { newName, userEmails, isPublic };
                const { data } = yield axios.patch(`/api/v2/workspaces/${workspaceId}`, payload);
                return data;
            });
        }
        Workspaces.updateWorkspace = updateWorkspace;
        function deleteWorkspace(workspaceId, relocationWorkspaceId) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.delete(`/api/v2/workspaces/${workspaceId}?relocationWorkspaceId=${relocationWorkspaceId}`);
                return data;
            });
        }
        Workspaces.deleteWorkspace = deleteWorkspace;
        function updateTourWorkspace(workspaceId, tourialId, tourialType) {
            return __awaiter(this, void 0, void 0, function* () {
                const { data } = yield axios.post(`/api/v2/workspaces/updateTourWorkspace/${tourialId}?type=${tourialType}&workspaceId=${workspaceId}`);
                return data;
            });
        }
        Workspaces.updateTourWorkspace = updateTourWorkspace;
    })(Workspaces = TourialApiCalls.Workspaces || (TourialApiCalls.Workspaces = {}));
})(TourialApiCalls || (TourialApiCalls = {}));
