export const featureFlags = [
    "hubspotIntegration",
    "marketoIntegration",
    "pardotIntegration",
    "pardotVisitorTracking",
    "salesforceFormIntegration",
    "hubspotVisitorTracking",
    "embedTool",
    "autoSave",
    "marketoVisitorTracking",
    "freeTrial",
    "outboundEmailLinks",
    "tourPages",
    "freemium",
    "automation",
    "shareLinks",
    "hideWatermark",
    "scheduling",
    "democenter",
    "dcAdvancedSettings",
    "htmlTours",
    "workspaces",
    "salesforceEngagement",
    "warmly",
    "passwordProtect",
    "dataExport",
];
export const HOME_WORKSPACE_PARAM = "tourial-home";
export const VIEW_ALL_WORKSPACE_PARAM = "tourial-view-all";
